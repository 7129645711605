import React from 'react';
import '../Styles/Quote.css';

const Quote = () => {
  return (
    <section className="quote">
      <p>Biosavia: Unique Stress Killer experience</p>
    </section>
  );
};

export default Quote;
